/* src/components/UserDetail.css */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar for horizontal scrolling */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 10px; /* Rounded corners for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #a0aec0; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #f1f1f1; /* Space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #4a5568; /* Darker color on hover */
}
